import * as React from "react";
import { createStyles, Group, Text, rem } from "@mantine/core";
import { ViewerPage } from "../viewer/ViewerPage";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 5)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: "#3e5590",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    // fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({ variant: "light", color: theme.primaryColor }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export function Hero() {
  const { classes } = useStyles();
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "15em",
          left: "2em",
          zIndex: "10000",
        }}
      >
        <Text className={classes.title}>blue ink sketches</Text>
        <Text mt="md">
          <br />
        </Text>
        <Text size="sm">
          <i>november 2022 - present</i>
          <br />
          <br />
          snippets from a sketchbook <br />
          all drawn with a blue micron pen, <br />
          in a mini sketchbook <br />
          depicting various landscapes <br />
          and pictures taken on my phone
        </Text>
        <Group mt={30}>
          {/* <Text size="sm">
            <a href="workshop"> workshop instructions</a>
          </Text> */}
          <Text size="sm">
            <a href="sketches"> view sketches</a>
          </Text>
        </Group>
      </div>
      <div className="desktop-only" style={{ height: "calc(-51vh)", width: "100vw" }}>
        <ViewerPage viewOnly />
      </div>
    </div>
  );
}
